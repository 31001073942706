body {
  height: 100vh !important;
  overflow: hidden !important;
  background-color: #22273b !important;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #22273b !important;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #151b2d !important;
  border-radius: 5px;
}

.emoji-mart {
  position: absolute !important;
  bottom: 70px !important;
}

.emoji-mart-preview {
  display: none !important;
}

.MuiInputLabel-outlined {
  color: rgba(173, 173, 173, 0.89) !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

.MuiInputBase-root {
  color: rgb(245, 245, 245) !important;
}

.MuiInputBase-root.Mui-disabled {
  color: rgb(255 255 255 / 53%) !important;
  cursor: default !important;
}

.MuiDivider-root {
  background-color: rgb(0 0 0 / 40%) !important;
}

.MuiDialog-paper {
  overflow-y: visible !important;
  background-color: #202035 !important;
  color: #dcddde !important;
  width: 530px;
}

.emoji-mart-dark {
  color: #fff;
  border-color: #22273b !important;
  background-color: #121628 !important;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #121628 !important;
  color: #fff;
  border-radius: 5px;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #22273b;
  background-color: #22273b;
}

a {
  color: #e3a7ff;
}

.MuiSnackbarContent-root {
  background-color: rgb(109 52 125) !important;
}
